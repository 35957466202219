#game{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: solid white 10px;
}
body{
    background-image: url('../public/assets/logo/Background.png');
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
header {
    width: 100%;
    height: 15vh;
}
header img {
    height: 100%;
    float: right;
    filter: brightness(0) invert(1);
}
